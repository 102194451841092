import React from 'react'
import { Layout } from '../components/layouts/signup'
import { SignupPage } from '../components/signup'

export default function IndexPage(props) {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <SignupPage {...props} />
    </Layout>
  )
}
